<template>
  <admin>
    <page-header :back="{
      to: { name: 'survey-reports.cs-dashboard' },
      text: trans('CS Dashboard'),
    }">
      <template v-slot:title><span v-text="trans('Upload CS Factor Target Score')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <v-card>
          <v-card-title>Target Score Information</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Year" type="number" class="dt-text-field" outlined v-model="form.year"
                    :rules="rules.year"></v-text-field>
                </v-col>
              </v-row>
             
              <v-row v-for="(factor,i) in form.factor" :key="i">
                <v-col cols="12">
                  <v-text-field :label="factor.title" class="dt-text-field" outlined type="number"
                     v-model="factor.score" :rules="rules.target"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-file-input label="Upload Dealer Target Score"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    outlined hide-details v-model="userUpload"></v-file-input>
                  <br />
                  <can :code="['admin']">
                    <p>Click <a :href="sampleFileUrlAdmin" download>here</a> to the download sample file.</p>
                  </can>
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-end my-6" flat tile>
              <v-btn large exact color="green darken-1" class="ma-1 white--text" @click="validate(false)"
                :loading="loadingSave" :disabled="loadingSave || loadingSaveAndExit">
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn large exact color="green lighten-1" class="ma-1 white--text" @click="validate(true)"
                :loading="loadingSaveAndExit" :disabled="loadingSave || loadingSaveAndExit">
                <v-icon left>mdi-content-save</v-icon>
                Save and Exit
              </v-btn>
              <v-btn large exact color="warning" class="ma-1 white--text" :to="{ name: 'survey-reports.cs-dashboard' }">
                <v-icon left>mdi-close</v-icon>
                Cancel
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {
        year: '',
        factor: []
      },
      listFactor: [],
      userUpload: null,
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      rules: {
        year: [(value) => !!value || "Year is required"],
        target: [(value) => !!value || "Target is required"],
        file: [
          (v) =>
            !!v || "File is required",
          (v) => (v && v.size > 0) || "File is required",
        ],
      },
      // rules: [(v) => !!v || "File is required", (v) => (v && v.size > 0) || "File is required"],

      sampleFileUrlAdmin:
        process.env.VUE_APP_API_BASE_URL.replace("api/", "") + "sample-files/factor-score.xlsx",
    };
  },
  computed: {
    ...mapGetters({
      factors: "surveyResults/GET_FACTOR",
      scoreFactors: "surveyResults/GET_SCORE_FACTOR",
    }),
  },
  created() {
    this.fetchFactors()
    this.fetchFactorScore()
  },
  methods: {
    ...mapActions({
      importSurvey: "surveyResults/importCsSurveyFactor",
      factorsData: "surveyResults/getFactorData",
      scoreFactorsData: "surveyResults/getFactorScore",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
    }),

    async fetchFactors() {
      await this.factorsData().then(() => {
        this.factors.map(item => {
          this.form.factor.push({
            title: item.title,
            slug: item.slug,
            score: ""
          });
        })
      });
    },

    async fetchFactorScore() {
      await this.scoreFactorsData().then(() => {
        this.form.factor.forEach(item => {
          const score = this.scoreFactors.find(_score => _score.factor_name === item.slug);
          if (score) {
            this.form.year = score.year
            item.score = score.default_target_score;
          }
         
        });
      });
    },


    async validate(isExit) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (isExit) {
        this.loadingSaveAndExit = true;
      } else {
        this.loadingSave = true;
      }

      await this.saveCsSurveyFactor(isExit);
    },

    async saveCsSurveyFactor(isExit) {
      this.confirmCsSaveSurveyFactor(isExit);
    },

    async confirmCsSaveSurveyFactor(isExit) {
      var formData = new FormData();
      formData.append("year", this.form.year);
      formData.append("file", this.userUpload);

      this.form.factor.forEach(value => {
        formData.append(`factors[${value.slug}]`, value.score);
      });

      await this.importSurvey(formData).then(() => {
        this.$refs.form.reset();
        this.loadingSaveAndExit = false;
        this.loadingSave = false;
        this.fetchFactorScore()
      });

      if (isExit) {
        this.$router.push({ name: "survey-reports.cs-dashboard" });
      }
    },
  },
};
</script>

<style></style>
